import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
    {
        path: '/services/:slug',
        name: 'Services-fr',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/Entry.vue'),
        meta: { lang: 'fr', json: 'servicesEntry', slug: true },
    },
    {
        path: '/centres',
        name: 'Centres-fr',
        component: () => import(/* webpackChunkName: "centres" */ '@/views/centres/Index.vue'),
        meta: { lang: 'fr', json: 'centres' },
    },
    {
        path: '/centres/:slug',
        name: 'Centre-fr',
        component: () => import(/* webpackChunkName: "centre" */ '@/views/centres/Entry.vue'),
        meta: { lang: 'fr', json: 'centresEntry', slug: true },
    },
    {
        path: '/evenements',
        name: 'Evenements-fr',
        component: () =>
            import(/* webpackChunkName: "evenements" */ '@/views/evenements/Index.vue'),
        meta: { lang: 'fr', json: 'evenements' },
    },
    {
        path: '/evenements/:slug',
        name: 'Evenement-fr',
        component: () => import(/* webpackChunkName: "evenement" */ '@/views/evenements/Entry.vue'),
        meta: { lang: 'fr', json: 'evenementsEntry', slug: true },
    },
    {
        path: '/a-propos',
        name: 'Apropos-fr',
        component: () => import(/* webpackChunkName: "apropos" */ '@/views/Apropos.vue'),
        meta: { lang: 'fr', json: 'aPropos' },
    },
    {
        path: '/contact',
        name: 'Contact-fr',
        component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
        meta: { lang: 'fr', json: 'contact' },
    },
    {
        path: '/aide',
        name: 'Aide-fr',
        component: () => import(/* webpackChunkName: "aide" */ '@/views/Aide.vue'),
        meta: { lang: 'fr', json: 'aide' },
    },
    {
        path: '/fondation',
        name: 'Fondation-fr',
        component: () => import(/* webpackChunkName: "fondation" */ '@/views/Fondation.vue'),
        meta: { lang: 'fr', json: 'fondation' },
    },
    // {
    //     path: '/carrieres',
    //     name: 'Carrieres-fr',
    //     component: () => import(/* webpackChunkName: "carrieres" */ '@/views/Carrieres.vue'),
    //     meta: { lang: 'fr', json: 'carrieres' },
    // },
    {
        path: '/faq',
        name: 'Faq-fr',
        component: () => import(/* webpackChunkName: "faq" */ '@/views/Faq.vue'),
        meta: { lang: 'fr', json: 'faq' },
    },
    {
        path: '*',
        name: 'p404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
})

router.beforeEach((to, from, next) => {
    const { lang } = to.meta
    document.documentElement.lang = lang
    i18n.locale = lang

    if (Store.getters['locale'] !== lang) {
        Store.dispatch('getGlobals')
        Store.dispatch('setLocale', lang)
    }

    setTimeout(() => {
        // Store.dispatch('resetContent')

        let type = 'pages'
        const { meta, params } = to
        let slug = meta.json
        let item = ''

        if (meta.slug) {
            item = params.slug
            type = '' // Use slug and ignore type
        }

        Store.dispatch('getContent', { lang: meta.lang, slug, type, item })
    }, 425)

    next()
})

export default router
