<template>
    <footer class="main_footer" v-if="globalsIsLoaded">
        <img src="@/assets/img/birds-footer.svg" alt="birds" class="birds" />
        <div class="wrap">
            <img src="@/assets/img/texture-2.svg" alt="texture" class="texture" />
            <div class="top">
                <svg
                    class="logo"
                    fill="none"
                    viewBox="0 0 450 37"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="#544269">
                        <path
                            d="m127.847 25.4508c.052 4.491-3.612 7.5286-8.46 7.5286-1.236 0-4.387-.4613-4.387-3.1942s2.681-3.8209 8.051-4.7c1.854-.3133 3.455-.5135 4.796-.7746v1.1315zm-5.571-14.5524c-4.865 0-7.685 2.2455-8.964 4.8566-.488.9922.069 2.1846 1.148 2.437.801.1828 1.602-.2263 1.959-.9574.783-1.6188 2.28-2.9766 5.753-2.9766 4.743 0 5.622 2.7329 5.675 4.4388.052 1.6537-1.445 2.3761-5.318 2.994-7.633 1.2359-11.454 3.4554-11.454 8.3555 0 4.1777 3.403 6.4493 7.998 6.4493 3.56 0 6.45-1.4448 8.669-3.3509 0 1.6537 1.34 2.9941 2.994 2.9941h.722s-.052-11.6106-.052-16.6674c0-5.0567-3.351-8.5643-9.13-8.5643"
                        />
                        <path
                            d="m139.231.0714103c-1.367-.3568453-2.611.6701787-2.611 2.0453397 0 1.12276.94 2.06274 2.063 2.06274 1.375 0 2.402-1.24461 2.045-2.61107-.192-.722392-.775-1.305531-1.497-1.4970097z"
                        />
                        <path
                            d="m136.829 11.2639v21.1061c0 2.0802 1.688 3.7687 3.768 3.7687v-21.1062c0-2.0801-1.688-3.7686-3.768-3.7686z"
                        />
                        <path
                            d="m155.089 21.7953c-3.612-.8269-5.884-1.3926-6.189-3.3509-.365-2.0105 1.341-3.9253 4.953-3.8208 2.141.0783 3.742.7398 4.639 1.8625.496.618 1.34.853 2.08.557l.078-.0348c1.14-.4439 1.532-1.8625.775-2.8286-1.602-2.0454-4.317-3.2813-7.633-3.2813-5.518 0-9.235 3.7165-8.617 8.1553.723 4.6477 5.466 5.6225 9.183 6.3449 4.386.9312 5.674 1.7059 5.674 3.6642 0 3.0462-3.246 3.7686-5.318 3.7686-2.637 0-4.656-.8268-5.753-2.289-.496-.6702-1.384-.9226-2.158-.6006-1.105.4526-1.497 1.8365-.766 2.7851 1.784 2.3326 4.874 3.8209 8.669 3.8209 4.491 0 9.078-2.2716 9.078-7.485 0-4.7435-4.544-6.3971-8.721-7.2762"
                        />
                        <path
                            d="m178.875 32.8836c-5.109 0-8.207-4.021-8.207-9.13s3.098-9.2345 8.207-9.2345 8.356 4.1255 8.356 9.2345-3.247 9.13-8.356 9.13zm0-21.9852c-7.067 0-11.967 5.727-11.967 12.8465s4.9 12.7942 11.967 12.7942c7.068 0 12.124-5.6747 12.124-12.7942s-5.004-12.8465-12.124-12.8465z"
                        />
                        <path
                            d="m206.588 10.9075c-3.047 0-5.466 1.2359-7.224 3.2551v-1.0357c0-1.0271-.827-1.8539-1.854-1.8539s-1.854.8356-1.854 1.8539v21.1583c0 1.027.835 1.8626 1.854 1.8626 1.018 0 1.854-.8356 1.854-1.8626v-11.5583c0-5.4658 2.785-8.103 6.606-8.103 4.021 0 6.344 2.3761 6.344 7.7897v11.8716c0 1.027.827 1.8626 1.854 1.8626s1.854-.8356 1.854-1.8626v-11.8716c0-9.1301-4.543-11.5061-9.443-11.5061"
                        />
                        <path
                            d="m230.322 21.7953c-3.612-.8269-5.883-1.3926-6.188-3.3509-.366-2.0105 1.34-3.9253 4.952-3.8208 2.141.0783 3.743.7398 4.639 1.8625.496.618 1.341.853 2.08.557l.079-.0348c1.14-.4439 1.532-1.8625.774-2.8286-1.601-2.0454-4.317-3.2813-7.633-3.2813-5.518 0-9.234 3.7165-8.616 8.1553.722 4.6477 5.466 5.6225 9.182 6.3449 4.387.9312 5.675 1.7059 5.675 3.6642 0 3.0462-3.247 3.7686-5.318 3.7686-2.637 0-4.656-.8268-5.753-2.289-.496-.6702-1.384-.9226-2.159-.6006-1.105.4526-1.497 1.8365-.765 2.7851 1.784 2.3326 4.874 3.8209 8.668 3.8209 4.491 0 9.078-2.2716 9.078-7.485 0-4.7435-4.543-6.3971-8.721-7.2762"
                        />
                        <path
                            d="m296.391 6.81634 4.073-3.98622c.905-.87906.426-2.410903-.818-2.628492-.549-.095739-1.106.113142-1.454.539616l-2.793 3.394406c-.801.9748-.253 2.46309.992 2.67197"
                        />
                        <path
                            d="m289.672 21.3253c.774-3.9253 3.559-6.8062 7.789-6.8062s6.711 2.8896 7.433 6.8062zm7.789-10.4269c-7.067 0-11.819 5.727-11.819 12.8465s5.057 12.742 12.124 12.742c3.69 0 6.702-1.4709 8.808-3.9079.757-.8704.496-2.2194-.54-2.7329l-.104-.0523c-.723-.3568-1.584-.1653-2.115.4439-1.462 1.6624-3.473 2.6372-6.049 2.6372-4.648 0-7.737-3.3509-8.26-7.8419h17.904c.713 0 1.288-.5831 1.288-1.2881 0-7.1195-4.126-12.8465-11.245-12.8465"
                        />
                        <path
                            d="m321.335 32.5707c-1.296.4787-2.637.2089-3.229-.3046-.879-.8268-1.035-1.9583-1.035-4.021v-26.31086c0-.966097-.784-1.749426-1.75-1.749426h-.217c-.966 0-1.75.783329-1.75 1.749426v26.57196c0 5.5703 2.011 7.5895 5.266 7.9463 1.123.148 2.672-.0174 4.091-.6527.974-.4439 1.288-1.6798.687-2.5676-.452-.6615-1.305-.9313-2.054-.6527"
                        />
                        <path
                            d="m343.225 25.4508c.052 4.491-3.612 7.5286-8.46 7.5286-1.236 0-4.387-.4613-4.387-3.1942s2.681-3.8209 8.051-4.7c1.854-.3133 3.455-.5135 4.796-.7746v1.1315zm-5.57-14.5524c-4.866 0-7.686 2.2455-8.974 4.8566-.487.9922.078 2.1846 1.149 2.437.801.1828 1.601-.2263 1.958-.9574.784-1.6188 2.281-2.9766 5.753-2.9766 4.744 0 5.623 2.7329 5.675 4.4388.052 1.6537-1.445 2.3761-5.318 2.994-7.633 1.2359-11.454 3.4554-11.454 8.3555 0 4.1777 3.403 6.4493 7.999 6.4493 3.56 0 6.449-1.4448 8.669-3.3509 0 1.6537 1.34 2.9941 2.994 2.9941h.722s-.052-11.6106-.052-16.6674c0-5.0567-3.351-8.5643-9.13-8.5643"
                        />
                        <path
                            d="m362.373 32.8833c-5.109 0-7.894-4.0211-7.894-9.1301s2.785-9.2345 7.894-9.2345 8.051 4.1255 8.051 9.2345-2.942 9.1301-8.051 9.1301zm10.061-32.8646817c-1.027 0-1.854.8355457-1.854 1.8538617v12.79422c-1.854-2.3239-4.647-3.7686-8.207-3.7686-7.12 0-11.663 5.7269-11.663 12.8464s4.543 12.742 11.663 12.742c3.56 0 6.345-1.3926 8.207-3.7164 0 1.8539 1.497 3.3509 3.351 3.3509h.357v-34.26592c0-1.027017-.836-1.8538593-1.854-1.8538593"
                        />
                        <path
                            d="m382.704 21.3252c.775-3.9253 3.56-6.8062 7.79-6.8062s6.71 2.8896 7.433 6.8062zm7.79-10.4268c-7.068 0-11.82 5.7269-11.82 12.8464s5.057 12.742 12.124 12.742c3.691 0 6.702-1.4709 8.808-3.9079.758-.8703.496-2.2194-.539-2.7329l-.105-.0522c-.722-.3569-1.584-.1654-2.115.4439-1.462 1.6623-3.472 2.6371-6.049 2.6371-4.647 0-7.737-3.3508-8.259-7.8419h17.894c.714 0 1.297-.5657 1.297-1.2794 0-7.1369-4.125-12.8639-11.245-12.8639"
                        />
                        <path
                            d="m421.348 25.4508c.052 4.491-3.612 7.5286-8.46 7.5286-1.236 0-4.387-.4613-4.387-3.1942s2.681-3.8209 8.051-4.7c1.854-.3133 3.455-.5135 4.796-.7746v1.1315zm-5.57-14.5524c-4.866 0-7.686 2.2455-8.974 4.8566-.487.9922.079 2.1846 1.149 2.437.801.1828 1.602-.2263 1.958-.9574.784-1.6188 2.281-2.9766 5.753-2.9766 4.744 0 5.623 2.7329 5.675 4.4388.052 1.6537-1.445 2.3761-5.318 2.994-7.633 1.2359-11.454 3.4554-11.454 8.3555 0 4.1777 3.403 6.4493 7.999 6.4493 3.56 0 6.449-1.4448 8.669-3.3509 0 1.6537 1.34 2.9941 2.994 2.9941h.722s-.052-11.6106-.052-16.6674c0-5.0567-3.351-8.5643-9.13-8.5643"
                        />
                        <path
                            d="m448.103 11.2639c-1.045 0-1.88.8442-1.88 1.88v11.8455c0 5.4223-2.272 7.7897-6.293 7.7897s-6.293-2.3761-6.293-7.7897v-11.8455c0-1.0358-.844-1.88-1.88-1.88-1.035 0-1.88.8442-1.88 1.88v11.8455c0 9.0778 5.109 11.5061 10.062 11.5061 4.952 0 10.061-2.4283 10.061-11.5061v-11.8455c0-1.0358-.844-1.88-1.88-1.88"
                        />
                        <path
                            d="m72.6138 36.1387c2.0801 0 3.7686-1.6885 3.7686-3.7687v-17.4854l-3.7686-5.03939z"
                        />
                        <path
                            d="m101.562 32.3699c0 2.0801 1.688 3.7686 3.769 3.7686v-26.33699l-3.769 5.03939z"
                        />
                        <path
                            d="m272.221.0195312h-13.003c-.314 0-.566.2523978-.566.5657258v32.298943c0 1.8016 1.454 3.2551 3.255 3.2551h.514v-31.83761c0-.31333.252-.56575.565-.56575h9.235c4.595 0 7.433 3.45532 7.433 7.48506 0 4.0298-2.89 7.4851-7.433 7.4851h-8.277v3.7164h8.277c6.554 0 11.297-5.109 11.297-11.1928 0-6.08377-4.543-11.2014567-11.297-11.2014567"
                        />
                        <path
                            d="m106.697.5063c-.287-.060925-.592-.043502-.87.043534-.331.095739-.636.295915-.862.591836l-14.6395 18.70393h.0174l-1.027 1.2707c-.1827.235-.5396.235-.7311 0l-1.0183-1.2794h.0261l-14.6394-18.70394c-.6701-.852951-1.9496-.896456-2.6719-.09573-.5658.62666-.6006 1.56662-.0871 2.23679l1.0793 1.40129.7049.90518 9.661 12.47221h.0174l5.2831 6.8062c1.1662.9922 2.8808.9922 4.0471 0l.6441-.8182 4.639-5.988 10.4269-13.45572 1.027-1.32296c.522-.67017.479-1.61013-.087-2.23679-.261-.287216-.592-.470013-.94-.539642"
                        />
                        <path
                            d="m254.031 20.525v.0522c0 1.0096.818 1.8365 1.827 1.8365h1.341v-3.7164h-1.341c-1.009 0-1.827.8181-1.827 1.8277z"
                        />
                        <path
                            d="m7.85061 32.6662c-1.25331.4351-2.54144.1653-3.11587-.3395-.87036-.8268-1.02702-1.967-1.02702-4.0297l-.01741-26.24996c0-1.02702-.82684-1.871259-1.84515-1.871259-1.01832 0-1.84516.835539-1.84516 1.871259v27.54676 1.5579c0 3.2378 2.63718 5.1961 5.24824 5.3614 1.10536.0697 2.62848-.0609 4.02105-.6614 1.01831-.4439 1.33161-1.7668.64406-2.6633-.47869-.618-1.32294-.7833-2.05404-.5222"
                        />
                        <path
                            d="m15.1442 21.3517c.7659-3.9253 3.525-6.8236 7.7201-6.8236s6.6408 2.8983 7.3545 6.8236zm7.7201-10.4443c-7.459 0-12.3156 6.519-11.6367 14.3086.5484 6.3449 5.8227 11.4191 12.1241 11.3408 3.6207-.0435 6.5625-1.5405 8.6165-3.995.705-.8442.4178-2.1497-.557-2.6371l-.148-.0784c-.7398-.3655-1.5753-.1566-2.0975.4439-1.4448 1.6711-3.438 2.6372-5.9881 2.6372-4.5955 0-7.6678-3.3596-8.1726-7.8593h17.703c.705 0 1.3055-.5658 1.3055-1.2795 0-7.1456-4.0906-12.8899-11.1405-12.8899"
                        />
                        <path
                            d="m46.7468 22.109c-3.4902-.8095-5.6921-1.3665-5.9881-3.2813-.3481-1.967 1.2969-3.8383 4.787-3.7338 2.0453.0783 3.5771.705 4.4562 1.7842.5048.618 1.3055.8791 2.0453.5918l.0784-.0348c1.0879-.4264 1.4883-1.8016.7746-2.7329-1.5405-2.0192-4.1777-3.2464-7.4067-3.2464-5.3353 0-8.9299 3.6381-8.3293 7.9812.6962 4.5432 5.2917 5.5006 8.8863 6.2143 4.2386.9052 5.4919 1.6624 5.4919 3.5859 0 2.9766-3.142 3.6903-5.1351 3.6903-2.5327 0-4.4649-.7921-5.5354-2.202-.5048-.6615-1.3491-.9487-2.115-.6267-1.0792.4439-1.4361 1.8191-.7224 2.7591 1.732 2.2629 4.7086 3.7076 8.3641 3.7076 4.3431 0 8.7819-2.2194 8.7819-7.3196 0-4.6477-4.3953-6.2579-8.4337-7.1195"
                        />
                    </g>
                </svg>
            </div>
            <div class="center">
                <div class="centre">
                    <div class="title_wrap">
                        <p class="regular-text">{{ globals.centresEntry.data[0].page.titre1 }}</p>
                        <h4 class="title small">{{ globals.centresEntry.data[0].page.titre }}</h4>
                        <div class="line"></div>
                    </div>
                    <address class="regular-text">
                        <span v-html="globals.centresEntry.data[0].page.informationsDeContact.adresse"></span>
                    </address>
                    <a :href="'tel:' + globals.centresEntry.data[0].page.informationsDeContact.telephone" class="phone_mail">
                        <img src="@/assets/img/icon-phone.svg" alt="Icon téléphone" />
                        <p class="regular-text">{{ globals.centresEntry.data[0].page.informationsDeContact.telephone }}</p>
                    </a>
                    <a :href="'mailto:' + globals.centresEntry.data[0].page.informationsDeContact.courriel" class="phone_mail">
                        <img src="@/assets/img/icon-mail.svg" alt="Icon courriel" />
                        <p class="regular-text">{{ globals.centresEntry.data[0].page.informationsDeContact.courriel }}</p>
                    </a>
                </div>
                <div class="centre">
                    <div class="title_wrap">
                        <p class="regular-text">{{ globals.centresEntry.data[1].page.titre1 }}</p>
                        <h4 class="title small">{{ globals.centresEntry.data[1].page.titre }}</h4>
                        <div class="line"></div>
                    </div>
                    <address class="regular-text">
                        <span v-html="globals.centresEntry.data[1].page.informationsDeContact.adresse"></span>
                    </address>
                    <a :href="'tel:' + globals.centresEntry.data[1].page.informationsDeContact.telephone" class="phone_mail">
                        <img src="@/assets/img/icon-phone.svg" alt="Icon téléphone" />
                        <p class="regular-text">{{ globals.centresEntry.data[1].page.informationsDeContact.telephone }}</p>
                    </a>
                    <a :href="'mailto:' + globals.centresEntry.data[1].page.informationsDeContact.courriel" class="phone_mail">
                        <img src="@/assets/img/icon-mail.svg" alt="Icon courriel" />
                        <p class="regular-text">{{ globals.centresEntry.data[1].page.informationsDeContact.courriel }}</p>
                    </a>
                </div>
                <div class="centre special">
                    <div class="title_wrap">
                        <p class="regular-text">{{ globals.footer.zoneInformations.surtitre }}</p>
                        <h4 class="title small">{{ globals.footer.zoneInformations.titre }}</h4>
                        <div class="line"></div>
                    </div>
                    <p class="regular-text">
                        <span v-html="globals.footer.zoneInformations.texte"></span>
                    </p>
                    <a :href="globals.footer.zoneInformations.boutonUrl[$route.meta.lang]" class="cta regular-text">{{ globals.footer.zoneInformations.bouton.customText }}</a>
                </div>
            </div>
            <div class="bottom">
                <nav>
                    <!-- <router-link :to="'/carrieres'" class="regular-text">Carrières</router-link> -->
                    <router-link :to="'/faq'" class="regular-text">FAQ</router-link>
                    <!-- <a href="#" class="regular-text">Politique de confidentialité</a> -->
                    <!-- <a href="#" class="regular-text">Conditions d'utilisation</a> -->
                </nav>
                <a
                    href="https://elmire.ca/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="credits"
                >
                    <p class="regular-text">Conception Elmire</p>
                    <img src="@/assets/img/elmire.png" alt="Elmire logo" />
                </a>
            </div>
        </div>
        <div class="notes">
            <p class="regular-text">
                <span v-html="globals.footer.texte"></span>
            </p>
        </div>
    </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped></style>
