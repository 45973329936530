<template>
    <div id="app">
        <NavBar />

        <div class="main-wrapper">
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </div>

        <FooterBar />

        <Popup v-if="pageIsLoaded" :popupData="globals.header.popup" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'
import Popup from '@/components/Popup'

import { initInViewAnimations } from '@/plugins/AnimationsLayer'

export default {
    components: { NavBar, FooterBar, Popup },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        initInViewAnimations()
                    }, 425)

                    this.toogleNavBarStatus()
                    window.addEventListener('scroll', () => {
                        this.toogleNavBarStatus()
                    })
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },

    methods: {
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.navbar')) {
                return
            }

            if (st >= 75) {
                document.querySelector('.navbar').classList.add('sticky')
            } else {
                document.querySelector('.navbar').classList.remove('sticky')
            }
        },
    },

    created() {
        // NavBar Follow

    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.555s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.main-wrapper {
    min-height: 100vh;
}
</style>
