<template>
    <div class="popup-component" :class="{ 'is-active': isActive }">
        <div class="popup-overlay" @click.prevent="closePopup"></div>
        <div class="popup-content">
            <img class="popup-img" :src="popupData.image.url" :alt="popupData.image.titre">
            <h3 class="popup-title">
                <span v-html="popupData.titre"></span>
            </h3>
            <p class="popup-text">
                <span v-html="popupData.texte"></span>
            </p>
            <a :href="popupData.boutonUrl[$route.meta.lang]" class="cta regular-text" :title="popupData.bouton.customText" target="_blank" rel="noopener noreferer" >{{ popupData.bouton.customText }}</a>
            <svg class="popup-close" height="16" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" @click.prevent="closePopup">
                <path d="M19.4582 16.2688L13.1863 9.99999L19.4536 3.72807C20.177 3.00767 20.177 1.84903 19.4566 1.12563L18.8743 0.541819C18.5321 0.190624 18.0638 0 17.5716 0H17.5686C17.0763 0 16.614 0.193609 16.2689 0.540282L9.99849 6.81527L3.72657 0.547966C3.00767 -0.172436 1.85053 -0.175433 1.12713 0.544965L0.543317 1.12278C0.192122 1.46798 0 1.93624 0 2.427C0 2.92079 0.193609 3.38454 0.540282 3.73122L6.81527 10.0016L0.546429 16.2735C-0.176969 16.9939 -0.176969 18.1525 0.543428 18.8774L1.12574 19.4598C1.47094 19.811 1.9377 20.0016 2.42996 20.0016C2.91922 20.0016 3.3875 19.8065 3.73268 19.4613L10.0015 13.1878L16.2719 19.454C16.6276 19.8127 17.1003 19.9943 17.5746 19.9943C18.0444 19.9943 18.5141 19.8157 18.8743 19.457L19.4566 18.8762C19.8093 18.534 20 18.0657 20 17.5735C20.003 17.0812 19.8094 16.6159 19.4627 16.2692L19.4582 16.2688Z" fill="#FAF5ED"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Popup',

    props: {
        popupData: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isActive: false,
        }
    },

    mounted () {
        if (window.localStorage.getItem('hasSeenPopup') !== 'true' && this.popupData.activer) {
            setTimeout(() => {
                this.isActive = true
            }, 1795)
        }
    },

    methods: {
        closePopup() {
            this.isActive = false
            window.localStorage.setItem('hasSeenPopup', 'true')
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/styles/02-Tools/_variables.scss';

.popup-component {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: all 0.65s ease-in-out;
}
.popup-component .popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

    background: rgba(0, 0, 0, 0);
    transition: all 0.65s ease-in-out;
}
.popup-component .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #FFFFFF;
    border-radius: 12px;
    z-index: 11;
    padding-bottom: 35px;

    text-align: center;

    transform: translate(-50%, -40%);
    opacity: 0;
    transition: all 0.65s ease-in-out;

    width: 100%;
    max-width: 675px;
}
.popup-img {
    display: block;
    width: 100%;
    max-height: 245px;
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.popup-component .popup-title {
    font-size: 28px;
    font-weight: 400;
    color: $color1;
    margin-bottom: 35px;
    line-height: 1.2;


    font-style: normal;

    padding: 0 4.5vw;
    padding-top: 35px;
}
.popup-component .popup-text {
    color: $color5;
    line-height: 1.5;
    margin-bottom: 35px;
    padding: 0 4.5vw;


    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.4;
}
.popup-component .popup-text.no-mb {
}
.popup-component .popup-text strong {
    font-size: 16px;
    font-weight: 600;
    color: $color1;
}
.popup-component .imgs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
}
.popup-component .imgs svg {
    width: auto;
    max-height: 50px;
    margin: 0 10px;
}
.popup-component .imgs img {
    width: auto;
    max-height: 50px;
    padding: 0 5px;
}
.popup-component .popup-close {
    position: absolute;
    top: -25px;
    right: -25px;
    z-index: 12;
    cursor: pointer;
}
.popup-component.is-active {
    z-index: 99999999;
}
.popup-component.is-active .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}
.popup-component.is-active .popup-content {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1);
}
@media (max-width: 767px) {
    .popup-component .popup-title br {
        display: none;
    }
}
@media (max-width: 599px) {
    .popup-component .popup-title br {
        display: none;
    }

    .popup-component.is-active .popup-content {
        opacity: 1;
        transform: translate3d(-50%, -50%, 0) scale(0.819875);
    }

    .popup-component .popup-title {
        font-size: 24px;
    }

    .popup-component .popup-text {
        font-size: 15px;

        strong {
            font-size: 15px;
            font-weight: bold;
            color: $color1;
        }
    }
}
</style>
