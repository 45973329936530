<template>
    <div>
        <header class="navbar">
            <div class="top">
                <div class="left">
                    <img src="@/assets/img/icon-time.svg" alt="Icon time" />
                    <p class="regular-text"><span v-html="globalsIsLoaded ? globals.header.titre1 : ''"></span></p>
                </div>
                <div class="right">
                    <router-link :to="'/evenements/la-marche-de-la-sobriete-des-maisons-peladeau'" class="regular-text link">Marche de la sobriété</router-link>
                    <router-link :to="'/fondation'" class="regular-text link">Fondation</router-link>
                    <a
                        :href="globalsIsLoaded ? globals.header.boutonUrl[$route.meta.lang] : ''"
                        target="_blank"
                        class="regular-text button"
                        rel="noopener noreferrer"
                        :title="globalsIsLoaded ? globals.header.bouton.customText : ''"
                    >
                        {{ globalsIsLoaded ? globals.header.bouton.customText : '' }}</a
                    >
                </div>
            </div>
            <div class="bottom">
                <a href="/" class="logo">
                    <img src="@/assets/img/logo.svg" alt="Logo Maisons Péladeau" />
                </a>
                <nav>
                    <a href="#" class="regular-text dropdown" :class="{ 'router-link-active': $route && $route.name && $route.name.includes('Services-') }" @mouseover="submenu = true">
                        <p>Services</p>
                        <img src="@/assets/img/arrow-down.svg" alt="Arrow Down" class="arrow" />
                        <div class="sub_menu" :class="{ actif: submenu }" @mouseleave="submenu = false">
                            <router-link :to="item.page.jsonUrl" class="regular-text" v-for="(item, i) in servicesList" :key="i">{{ item.page.titre }}</router-link>
                            <img src="@/assets/img/triangle.svg" alt="triangle" class="triangle" />
                        </div>
                    </a>
                    <router-link :to="'/centres'" class="regular-text">Centres</router-link>
                    <router-link :to="'/evenements'" class="regular-text">Événements</router-link>
                    <router-link :to="'/a-propos'" class="regular-text">À propos</router-link>
                    <router-link :to="'/contact'" class="regular-text">Contact</router-link>

                    <a :href="'tel:' + (globalsIsLoaded ? globals.header.titre2 : '')" class="cta regular-text">{{ globalsIsLoaded ? globals.header.titre2 : '' }}</a>

                    <div class="burger" @click.prevent="showMobileMenu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </nav>
            </div>
        </header>

        <div v-if="globalsIsLoaded" class="mobile_menu" :class="{ show: menuIsOpen }" @click.prevent.stop="menuIsOpen = false">
            <div class="menu">
                <img
                    src="@/assets/img/close.svg"
                    class="close"
                    alt="Close"
                    @click.prevent="showMobileMenu"
                />
                <nav>
                    <p class="title small line">Services</p>
                    <div class="sub">
                        <router-link :to="item.page.jsonUrl" class="regular-text" v-for="(item, i) in servicesList" :key="i">{{ item.page.titre }}</router-link>
                    </div>
                    <router-link :to="'/centres'" class="title small line">Centres</router-link>
                    <router-link :to="'/evenements'" class="title small line">Événements</router-link>
                    <router-link :to="'/a-propos'" class="title small line">À propos</router-link>
                    <router-link :to="'/contact'" class="title small line">Contact</router-link>

                    <a :href="'tel:' + (globalsIsLoaded ? globals.header.titre2 : '')" class="cta regular-text">{{ globalsIsLoaded ? globals.header.titre2 : '' }}</a>
                </nav>
            </div>
            <div class="overlay" @click.prevent="showMobileMenu"></div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    data() {
        return {
            submenu: false,
            menuIsOpen: false,
        }
    },
    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                }
            },
            immediate: true,
        },
    },

    computed: {
        servicesList() {
            if (!this.globalsIsLoaded) {
                return []
            }

            return this.globals.servicesEntry.data
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
    methods: {
        showMobileMenu() {
            if (this.menuIsOpen) {
                this.menuIsOpen = false
            } else {
                this.menuIsOpen = true
            }
        },
    },
}
</script>

<style lang="scss" scoped></style>
